import React from "react";
import { aboutUs } from "../../utils/constants";

const AboutUs = () => {
  return (
    <div className="container-xxl py-6">
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
            <div
              className="position-relative overflow-hidden ps-5 pt-5 h-100"
              style={{ minHeight: "400px" }}
            >
              <img
                className="position-absolute top-0 start-0 bg-white pe-3 pb-3"
                src={aboutUs}
                alt=""
                style={{ width: "600px", height: "600px" }}
              />
            </div>
          </div>
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="h-100">
              <h6 className="text-primary text-uppercase mb-2">About Us</h6>
              <h1 className="display-6 mb-4">
                PURPOSEFUL EDUCATION---Converting an open mind with an empty
                one.
              </h1>
              <p>
                (i)To keep in reach to the learners and respond their needs.
                (ii). To chart a helpful career and academic path for the
                students.
              </p>
              <p className="mb-4">
                To be an Institute of excellence for holistic developement
                creating a supporting, creative and Productive learning
                environment for the keen learners fostering education i.e.
                accessible , affordable and innovative.
              </p>
              <div className="row g-2 mb-4 pb-2">
                <div className="col-sm-6">
                  <i className="fa fa-check text-primary me-2"></i>To excel
                  offering individual and shared attention .
                </div>
                <div className="col-sm-6">
                  <i className="fa fa-check text-primary me-2"></i>To stay
                  rooted preserving our traditional heritage.
                </div>
                <div className="col-sm-6">
                  <i className="fa fa-check text-primary me-2"></i>To stay
                  poised to be part of the new challenges of modern day world.
                </div>
              </div>
              <div className="row g-4">
                <div className="col-sm-6">
                  <a className="btn btn-primary py-3 px-5" href="/">
                    Read More
                  </a>
                </div>
                <div className="col-sm-6">
                  <a
                    className="d-inline-flex align-items-center btn btn-outline-primary border-2 p-2"
                    href="tel:+0123456789"
                  >
                    <span className="flex-shrink-0 btn-square bg-primary">
                      <i className="fa fa-phone-alt text-white"></i>
                    </span>
                    <span className="px-3">
                      +91 96135 90648, +91 82668 99843
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
