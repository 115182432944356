import { useEffect, useState } from "react";
import "./App.css";
import Topbar from "./components/layout/Topbar";
import Spinner from "./components/spinner/Spinner";
import Navbar from "./components/layout/Navbar";
import Home from "./components/home/Home";
import Footer from "./components/layout/Footer";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AboutUs from "./components/home/AboutUs";
import HomeImageCarousel from "./components/carousel/ImagesCarousel";
import Team from "./components/home/Team";
import Contact from "./components/contact/Contact";
import { Analytics } from "@vercel/analytics/react";

function App() {
  const [spinnerLoader, setSpinnerLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setSpinnerLoader(false);
    }, 1000);
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/team",
      element: <Team />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
    {
      path: "/about",
      element: (
        <>
          <HomeImageCarousel />
          <AboutUs />
        </>
      ),
    },
  ]);

  return (
    <div className="App">
      {spinnerLoader && <Spinner />}

      <Topbar />

      <Navbar />

      <RouterProvider router={router} />

      <Footer />

      <Analytics />
    </div>
  );
}

export default App;
