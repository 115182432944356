import React from "react";

const Facts = () => {
  return (
    <div className="container-fluid facts py-5 pt-lg-0">
      <div className="container py-5 pt-lg-0">
        <div className="row gx-0">
          <div className="col-lg-4 wow fadeIn" data-wow-delay="0.1s">
            <div
              className="bg-white shadow d-flex align-items-center h-100 p-4"
              style={{ minHeight: "150px" }}
            >
              <div className="d-flex">
                <div className="flex-shrink-0 btn-lg-square bg-primary">
                  <i className="fa fa-school text-white"></i>
                </div>
                <div className="ps-4">
                  <h5>OUR MISSION</h5>
                  <span>
                    To keep in reach to the learners and respond their needs.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
            <div
              className="bg-white shadow d-flex align-items-center h-100 p-4"
              style={{ minHeight: "150px" }}
            >
              <div className="d-flex">
                <div className="flex-shrink-0 btn-lg-square bg-primary">
                  <i className="fa fa-users text-white"></i>
                </div>
                <div className="ps-4">
                  <h5>OUR VISION</h5>
                  <span>
                    To chart a helpful career and academic path for the
                    students.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
            <div
              className="bg-white shadow d-flex align-items-center h-100 p-4"
              style={{ minHeight: "150px" }}
            >
              <div className="d-flex">
                <div className="flex-shrink-0 btn-lg-square bg-primary">
                  <i className="fa fa-file-alt text-white"></i>
                </div>
                <div className="ps-4">
                  <h5>GOAL</h5>
                  <span>
                    To excel offering individual and shared attention .
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Facts;
