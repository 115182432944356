import React from "react";
import { homeImageCarousel } from "../../utils/constants";
import DownloadIcon from "../../img/download-icon.svg";

const brochureDownloadUrl =
  "https://drive.google.com/file/d/1CVfkTvXKQVKQmOB5-uu1lg6GTi00MTsl/view?usp=sharing";

const HomeCarousel = () => {
  return (
    <div className="container-fluid p-0 wow fadeIn" data-wow-delay="0.1s">
      <div
        id="header-carousel"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          {homeImageCarousel.map((image, index) => (
            <div
              key={index}
              className={`carousel-item ${index === 0 ? "active" : ""}`}
            >
              <img
                className="w-100"
                src={image}
                alt={`KAD college ${index + 1}`}
              />
              <div className="carousel-caption">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-7">
                      <h1 className="display-5 text-light mb-5 animated slideInDown">
                        Welcome to KATIGORAH ANCHALIK DEGREE COLLEGE
                      </h1>
                      <a
                        href={brochureDownloadUrl}
                        target="_blank"
                        download
                        className="btn btn-info py-sm-3 px-sm-5 text-dark"
                        rel="noreferrer"
                      >
                        <img
                          src={DownloadIcon}
                          alt="download icon"
                          width={23}
                          height={23}
                          className="me-2"
                        />
                        Download Prospectus
                      </a>
                      <a
                        href="/team"
                        className="btn btn-light py-sm-3 px-sm-5 ms-3"
                      >
                        Our Faculty
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#header-carousel"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#header-carousel"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default HomeCarousel;
