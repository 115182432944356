const cloudinaryBaseURL =
  "https://res.cloudinary.com/dnm2tmxrh/image/upload/f_auto,q_auto/v1/KAD%20college";

const aboutUs = `${cloudinaryBaseURL}/college-images/about-2`;

const homeImageCarousel = [
  `${cloudinaryBaseURL}/college-images/kad1`,
  `${cloudinaryBaseURL}/college-images/kad2`,
  `${cloudinaryBaseURL}/college-images/kad3`,
  `${cloudinaryBaseURL}/college-images/kad4`,
  `${cloudinaryBaseURL}/college-images/kad5`,
  `${cloudinaryBaseURL}/college-images/kad6`,
  `${cloudinaryBaseURL}/college-images/kad7`,
  `${cloudinaryBaseURL}/college-images/kad8`,
  `${cloudinaryBaseURL}/college-images/kad9`,
  `${cloudinaryBaseURL}/college-images/kad10`,
];

const faculty = [
  {
    name: "Burhanul Hoque Choudhary",
    designation: "Principal",
    url: `${cloudinaryBaseURL}/faculty/Burhanul-Hoque-Choudhary`,
  },
  {
    name: "Dr. Hussain Ahmed Bhuiya",
    designation: "Vice principal",
    url: `${cloudinaryBaseURL}/faculty/Dr.-Hussain-Ahmed-Barbhuiya`,
  },
  {
    name: "AS Md Karimul Bari",
    designation: "Assistant professor, Department of English",
    url: `${cloudinaryBaseURL}/faculty/Md-Karimul-Bari`,
  },
  {
    name: "Dr. Amrita Choudhary",
    designation: "Department of Bengali",
    url: `${cloudinaryBaseURL}/faculty/Amrita Choudhary`,
  },
  {
    name: "Rupom Sharma",
    designation: "Assistant professor, Department of English",
    url: `${cloudinaryBaseURL}/faculty/Rupom-Sharma`,
  },
  {
    name: "Didarul Karim Barbhuiya",
    designation: "Assistant Professor, Department of Economics",
    url: `${cloudinaryBaseURL}/faculty/Didarul-Karim-Barbhuiya-3`,
  },
  {
    name: "Dr. Farid Ahmed Laskar",
    designation: "Department of Economics",
    url: `${cloudinaryBaseURL}/faculty/Farid Ahmed Laskar`,
  },
  {
    name: "Md. Khaleed Barbhuiya",
    designation: "Department of History",
    url: `${cloudinaryBaseURL}/faculty/Sahidul-Haque-Barbhuiya`,
  },
  {
    name: "Sahidul Haque Barlaskar",
    designation: "Department of political science",
    url: `${cloudinaryBaseURL}/faculty/Sahidul Hoque Barlaskar`,
  },
  {
    name: "Rukon Uddin talukder",
    designation: "Department of Political Science",
    url: `${cloudinaryBaseURL}/faculty/Rukon Uddin talukder-1`,
  },
  {
    name: "Prema Kurmi",
    designation: "Department of Bengali",
    url: `${cloudinaryBaseURL}/faculty/Prema Kumari`,
  },
  {
    name: "Nazrin Akthar Barbhuiya",
    designation: "Department of Bengali",
    url: `${cloudinaryBaseURL}/faculty/Nazrin Akthar Barbhuiya`,
  },
  {
    name: "Dr.Shelly Barbhuiya",
    designation: "Department of Economics",
    url: `${cloudinaryBaseURL}/faculty/Dr.Shelly Barbhuiya`,
  },
  {
    name: "Nayem Hussain",
    designation: "Department of History",
    url: `${cloudinaryBaseURL}/faculty/Nayem Hussain`,
  },
  {
    name: "Dr. Swapna Begum Choudhury",
    designation: "Department of Bengali",
    url: `${cloudinaryBaseURL}/faculty/Dr. Swapna Begum Choudhury`,
  },
  {
    name: "Joy Prakash Sharma",
    designation: "Department of Political Science",
    url: `${cloudinaryBaseURL}/faculty/Joy Prakash Sharma`,
  },
  {
    name: "ASM Latiful Bari",
    designation: "Department of Arabic",
    url: `${cloudinaryBaseURL}/faculty/ASM Latiful Bari`,
  },
  {
    name: "Sapna Yasmin",
    designation: "Department of English",
    url: `${cloudinaryBaseURL}/faculty/Sapna Yasmin`,
  },
];

export { cloudinaryBaseURL, faculty, aboutUs, homeImageCarousel };
