import Carousel from "react-bootstrap/Carousel";
import { homeImageCarousel } from "../../utils/constants";

function HomeImageCarousel() {
  return (
    <>
      <h5 style={{ marginBottom: "20px" }}>Images gallery</h5>
      <Carousel>
        {homeImageCarousel?.map((image) => (
          <Carousel.Item key={image}>
            <img
              src={image}
              alt=""
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  );
}

export default HomeImageCarousel;
