import React from "react";

const Contact = () => {
  return (
    <div>
      <div className="col-lg-3 col-md-6">
        <h4 className="text-white mb-4">Get In Touch</h4>
        <h2 className="text-primary mb-4">
          <i className="fa fa-thin fa-school text-primary me-2"></i>KATIGORAH
          ANCHALIK DEGREE COLLEGE
        </h2>
        <p className="mb-2">
          <i className="fa fa-map-marker-alt me-3"></i>Katigorah, Assam
        </p>
        <p className="mb-2">
          <i className="fa fa-phone-alt me-3"></i>+91 96135 90648, +91 82668
          99843
        </p>
        <p className="mb-2">
          <i className="fa fa-envelope me-3"></i>kadckaraikandi@gmail.com
        </p>
      </div>
    </div>
  );
};

export default Contact;
