import React from "react";
import { faculty } from "../../utils/constants";

const Team = () => {
  return (
    <div className="container-xxl py-6">
      <div className="container">
        <div
          className="text-center mx-auto mb-5 wow fadeInUp"
          data-wow-delay="0.1s"
          style={{ maxWidth: "500px" }}
        >
          <h6 className="text-primary text-uppercase mb-2">Meet The Team</h6>
          <h1 className="display-6 mb-4">
            We Have Great & Experienced Faculty
          </h1>
        </div>
        <div className="row g-0 team-items">
          {faculty?.map((member) => (
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
              key={member.url}
            >
              <div className="team-item position-relative">
                <div className="position-relative">
                  <img
                    // className="img-fluid"
                    src={member.url}
                    alt={member.name}
                    style={{ width: "100%", height: 300 }}
                  />
                </div>
                <div className="bg-light text-center p-4">
                  <h5 className="mt-2">{member.name}</h5>
                  <span>{member.designation}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
