import React from "react";
import Facts from "./Facts";
import HomeCarousel from "../carousel/HomeCarousel";
import AboutUs from "./AboutUs";
import Team from "./Team";
import HomeImageCarousel from "../carousel/ImagesCarousel";

const Home = () => {
  return (
    <>
      <HomeCarousel />

      <Facts />

      <HomeImageCarousel />

      <AboutUs />

      <Team />
    </>
  );
};

export default Home;
