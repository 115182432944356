import React from "react";

const Navbar = () => {
  // Function to check if a given link should be active based on the current URL
  const isActive = (link) => {
    // Convert both the current URL and the link URL to lowercase for comparison
    const currentUrl = window.location.pathname.toLowerCase();
    const linkUrl = link.toLowerCase();

    // Check if the current URL matches the link URL
    return currentUrl === linkUrl ? "active" : "";
  };

  return (
    <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0">
      <a
        href="/"
        className="navbar-brand d-flex align-items-center border-end px-4 px-lg-5"
      >
        <h2 className="m-0">
          <i className="fa fa-thin fa-school text-primary me-2"></i>KAD College
        </h2>
      </a>
      <button
        type="button"
        className="navbar-toggler me-4"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <div className="navbar-nav ms-auto p-4 p-lg-0">
          <a href="/" className={`nav-item nav-link ${isActive("/")}`}>
            Home
          </a>
          <a
            href="/about"
            className={`nav-item nav-link ${isActive("/about")}`}
          >
            About
          </a>
          <a href="/team" className={`nav-item nav-link ${isActive("/team")}`}>
            Faculty
          </a>
          <div className="nav-item dropdown">
            <a
              href="!#"
              className={`nav-link dropdown-toggle ${isActive("!#")}`}
              data-bs-toggle="dropdown"
            >
              Pages
            </a>
            <div className="dropdown-menu bg-light m-0">
              <a
                href="/feature"
                className={`dropdown-item ${isActive("/feature")}`}
              >
                Features
              </a>
              <a
                href="/appointment"
                className={`dropdown-item ${isActive("/appointment")}`}
              >
                Appointment
              </a>
              <a href="/team" className={`dropdown-item ${isActive("/team")}`}>
                Our Faculty
              </a>
              <a
                href="/testimonial"
                className={`dropdown-item ${isActive("/testimonial")}`}
              >
                Testimonial
              </a>
              <a href="/404" className={`dropdown-item ${isActive("/404")}`}>
                404 Page
              </a>
            </div>
          </div>
          <a
            href="/contact"
            className={`nav-item nav-link ${isActive("/contact")}`}
          >
            Contact
          </a>
        </div>
        <a href="/" className="btn btn-primary py-4 px-lg-5 d-none d-lg-block">
          Get Started<i className="fa fa-arrow-right ms-3"></i>
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
